var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topBar"},[_c('div',{staticClass:"remove"},[_c('p',{attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.title))]),(_vm.displayDelete)?_c('i',{staticClass:"fas fa-trash-alt",on:{"click":function($event){return _vm.deleteItem()}}}):_vm._e()]),(_vm.isChanged)?_c('div',{staticStyle:{"display":"flex"}},[_c('MainButton',{attrs:{"clickFunction":function () {
          _vm.discard()
        },"title":"Discard","styleClass":"secondary","buttonStyle":{
        'margin-left': '10px',
        padding: '7px 15px',
        width: '150px',
      }}}),_c('MainButton',{attrs:{"clickFunction":function () {
          _vm.save()
        },"title":"Update","styleClass":"primary","buttonStyle":{
        'margin-left': '10px',
        padding: '7px 15px',
        width: '150px',
      }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }