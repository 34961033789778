<template>
  <div class="pageWrapper">
    <Loader v-if="!role" :color="'#444'" />
    <div v-else>
      <PrevPage title="Back to roles" />
      <SaveDiscardMenu
        :discardChanges="discardChanges"
        :saveChanges="saveChanges"
        :isChanged="isChanged"
        :title="role.title"
        :displayDelete="true"
        :delete="{
          message: 'Are you sure you want to delete ' + role.title + '?',
          execute: deleteRole,
        }"
      />
      <MessageBox
        :data="{
          transition: 0.2,
          height: '4vw',
          close: { auto: true, time: 5 },
        }"
      />
      <div class="divider">
        <InfoWrapperBox>
          <div class="permissions">
            <div class="permissionsHeader">
              <div
                v-for="category in role.permissions.categories"
                :key="category.id"
              >
                <H4>{{ category.title }}</H4>
                <RolePermissionItem
                  :permission="permission"
                  :selectedRolePermissions="
                    role.permissions.selectedRolePermissions
                  "
                  v-for="permission in category.permissions"
                  :key="permission.id"
                />
                <br />
              </div>
            </div>
          </div>
        </InfoWrapperBox>
        <div>
          <InfoWrapperBox>
            <H4>Role name</H4>
            <StandardInput
              type="text"
              class="textInput"
              placeholder="Role name"
              v-model="role.title"
            />
          </InfoWrapperBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Loader from '@/components/Loader'
import SaveDiscardMenu from '../../components/SaveDiscardMenu.vue'
import PrevPage from '../../components/PrevPage.vue'
import MessageBox from '../../components/MessageBox.vue'
import RolePermissionItem from '../../components/management/RolePermissionItem.vue'
import H4 from '../../layout/typography/H4.vue'
import StandardInput from '../../layout/StandardInput.vue'
import InfoWrapperBox from '../../layout/InfoWrapperBox.vue'

export default {
  components: {
    Loader,
    SaveDiscardMenu,
    PrevPage,
    MessageBox,
    RolePermissionItem,
    H4,
    StandardInput,
    InfoWrapperBox,
  },
  data() {
    return {
      role: undefined,
      baseRole: undefined,
    }
  },
  created() {
    this.getRole()
  },
  computed: {
    isChanged() {
      if (JSON.stringify(this.role) == JSON.stringify(this.baseRole)) {
        return false
      }
      return true
    },
  },
  methods: {
    deleteRole() {
      axios
        .post('roles/deleteRole', {
          roleId: this.role.id
        })
        .then((res) => {
          this.$router.back()
        })
        .catch((err) => {
          console.log(err.response.data.msg);
          this.$store.commit('SET_MESSAGEBOX', { message: err.response.data.msg, type: 'error' })
        })
    },
    saveChanges() {
      axios
        .post('roles/updateRole', {
          role: this.role
        })
        .then((res) => {
          this.$store.commit('SET_MESSAGEBOX', { message: 'Role has been updated', type: 'success' })
          this.baseRole = JSON.parse(JSON.stringify(this.role))
        })
        .catch((err) => {
          this.$store.commit('SET_MESSAGEBOX', { message: err.response.data.msg, type: 'error' })
        })
    },
    discardChanges() {
      this.role = JSON.parse(JSON.stringify(this.baseRole))
    },
    getRole() {
      axios
        .post('roles/getRole', {
          roleId: this.$route.params.roleId,
        })
        .then((res) => {
          this.role = JSON.parse(JSON.stringify(res.data))
          this.baseRole = JSON.parse(JSON.stringify(res.data))
        })
    },
  },
}
</script>

<style scoped lang="scss">
.divider {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% minmax(30%, 200px);
  grid-gap: 20px;
}

.info {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 700px) {
  .pageWrapper {
    margin: auto;
    width: 100%;
  }
  .divider {
    position: relative;
    width: 100%;
    display: inline;
  }
}
</style>