<template>
  <div class="permissionParent">
    <md-checkbox
      style="top: 5px; margin: 0; margin-right: 10px; border-color: black"
      v-model="selectedRolePermissions"
      :value="permission.id"
      class="md-primary"
      :id="'box' + permission.id"
    />
    <label style="cursor: pointer" :for="'box' + permission.id">
      <H5>{{ permission.title }}</H5>
    </label>
    <br />
  </div>
</template>

<script>
import H5 from '../../layout/typography/H5'

export default {
  components: { H5 },
  props: ['selectedRolePermissions', 'permission']
}
</script>

<style lang="scss" scoped>
.permissionParent {
  cursor: pointer;
  display: flex;
  vertical-align: middle;
  margin: 15px 0;
}

.permissionParent p {
  top: 6px;
  position: relative;
}
</style>