<template>
  <div class="topBar">
    <div class="remove">
      <p id="title">{{ title }}</p>
      <i
        v-if="displayDelete"
        @click="deleteItem()"
        class="fas fa-trash-alt"
      ></i>
    </div>
    <div v-if="isChanged" style="display: flex">
      <MainButton
        :clickFunction="
          () => {
            discard()
          }
        "
        title="Discard"
        styleClass="secondary"
        :buttonStyle="{
          'margin-left': '10px',
          padding: '7px 15px',
          width: '150px',
        }"
      />
      <MainButton
        :clickFunction="
          () => {
            save()
          }
        "
        title="Update"
        styleClass="primary"
        :buttonStyle="{
          'margin-left': '10px',
          padding: '7px 15px',
          width: '150px',
        }"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'

export default {
  components: {
    MainButton,
  },
  props: {
    discardChanges: { required: true },
    saveChanges: { required: true },
    isChanged: { required: true },
    title: { required: true },
    delete: { required: false },
    displayDelete: { required: false, default: false },
  },
  methods: {
    discard() {
      this.discardChanges()
    },
    deleteItem() {
      var r = confirm(this.delete.message);
      if (r == true) {
        this.delete.execute()
      }
    },
    save() {
      this.saveChanges()
    },
  }
}
</script>

<style scoped lang="scss">
.remove {
  float: left;
  display: flex;
}

#title {
  padding: 6px 0;
}

.topBar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.remove i {
  transition: 0.2s ease-in-out;
  margin: auto 10px;
  cursor: pointer;
  font-size: 10pt;
}
.remove i:hover {
  font-size: 12pt;
}
.remove p {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  position: relative;
  display: flex;
  margin: auto 0;
}

@media screen and (max-width: 700px) {
  #title {
    font-size: 13pt;
  }
}
</style>