var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageWrapper"},[(!_vm.role)?_c('Loader',{attrs:{"color":'#444'}}):_c('div',[_c('PrevPage',{attrs:{"title":"Back to roles"}}),_c('SaveDiscardMenu',{attrs:{"discardChanges":_vm.discardChanges,"saveChanges":_vm.saveChanges,"isChanged":_vm.isChanged,"title":_vm.role.title,"displayDelete":true,"delete":{
        message: 'Are you sure you want to delete ' + _vm.role.title + '?',
        execute: _vm.deleteRole,
      }}}),_c('MessageBox',{attrs:{"data":{
        transition: 0.2,
        height: '4vw',
        close: { auto: true, time: 5 },
      }}}),_c('div',{staticClass:"divider"},[_c('InfoWrapperBox',[_c('div',{staticClass:"permissions"},[_c('div',{staticClass:"permissionsHeader"},_vm._l((_vm.role.permissions.categories),function(category){return _c('div',{key:category.id},[_c('H4',[_vm._v(_vm._s(category.title))]),_vm._l((category.permissions),function(permission){return _c('RolePermissionItem',{key:permission.id,attrs:{"permission":permission,"selectedRolePermissions":_vm.role.permissions.selectedRolePermissions}})}),_c('br')],2)}),0)])]),_c('div',[_c('InfoWrapperBox',[_c('H4',[_vm._v("Role name")]),_c('StandardInput',{staticClass:"textInput",attrs:{"type":"text","placeholder":"Role name"},model:{value:(_vm.role.title),callback:function ($$v) {_vm.$set(_vm.role, "title", $$v)},expression:"role.title"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }